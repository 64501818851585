/* IMPORTS */

import React from "react"
import styled from "styled-components"
import { graphql, useStaticQuery, Link } from "gatsby"
import Img from "gatsby-image"
import Iframe from "react-iframe"

import { HalfColumn, TwoColumn } from "../global"

/* ICONS */

import arts from "../../images/icons/Icon/Arts.png"
import education from "../../images/icons/Icon/Education.png"
import skilling from "../../images/icons/Icon/Skilling.png"
import fitness from "../../images/icons/Icon/Fitness.png"
import travel from "../../images/icons/Icon/Travel.png"
import health from "../../images/icons/Icon/Health.png"
import business from "../../images/icons/Icon/Business.png"
import person from "../../images/icons/Icon/Person.png"
import store from "../../images/icons/Icon/Store.png"

import artsgrey from "../../images/icons/Icon/Gray/Arts.png"
import skillinggrey from "../../images/icons/Icon/Gray/Skilling.png"
import healthgrey from "../../images/icons/Icon/Gray/Health.png"
import travelgrey from "../../images/icons/Icon/Gray/Travel.png"
import businessgrey from "../../images/icons/Icon/Gray/Business.png"

import line from "../../images/vertlign.png"

/* FUNCTION */

const ShopHeader = () => {
    return (
        <div>
            <TextContainer>
                <Headline>
                    Explore a world of opportunities to grow
                </Headline>
                <HeaderText>
                    The journey to self-care and self-enhancement for the young and the progressive goes via Dili. In the form of quick and easy loans across the following areas and more.
                </HeaderText>
            </TextContainer>
                <IconRowContainer>
                    <IconsRow>
                        <ActiveIcons>
                            <NavLink to="/categories/loans-for-education">
                                <IconContainer>
                                    <Icon src={education} alt="Logo"/>
                                    <IconText>Education beyond the classroom</IconText>
                                </IconContainer>
                            </NavLink>
                            <NavLink to="/categories/loans-for-health-fitness">
                                <IconContainer>
                                    <Icon src={fitness} alt="Logo"/>
                                    <IconText>Health and Fitness</IconText>
                                </IconContainer>
                            </NavLink>
                            <NavLink to="/categories/loans-for-cosmetic-medical-treatments">
                                <IconContainer>
                                    <Icon src={health} alt="Logo"/>
                                    <IconText>Grooming and Cosmetic Treatments</IconText>
                                </IconContainer>
                            </NavLink>
                            <NavLink to="/categories/loans-for-career-enhancement">
                                <IconContainer>
                                    <Icon src={skilling} alt="Logo"/>
                                    <IconText>Skills for career progress</IconText>
                                </IconContainer>
                            </NavLink>
                            <NavLink to="/categories/loans-for-small-business">
                                <IconContainer>
                                    <Icon src={business} alt="Logo"/>
                                    <IconText>Set up or boost a small business</IconText>
                                </IconContainer>
                            </NavLink>
                            <NavLink to="/categories/loans-to-nurture-talent">
                                <IconContainer>
                                    <Icon src={arts} alt="Logo"/>
                                    <IconText>Nurture creative and sporting talent</IconText>
                                </IconContainer>
                            </NavLink>
                            <NavLink to="/categories/loans-for-travelling">
                                <IconContainer>
                                    <Icon src={travel} alt="Logo"/>
                                    <IconText>Exotic travel experiences</IconText>
                                </IconContainer>
                            </NavLink>
                            <NavLink to="/categories/loans-for-self-growth/">
                                <IconContainer>
                                    <Icon src={store} alt="Logo"/>
                                    <IconText>Other merchants</IconText>
                                </IconContainer>
                            </NavLink>
                            <NavLink to="https://docs.google.com/forms/d/e/1FAIpQLScbyaRY2G20xafoCvqNfw_37ZlsT9-bR63qV-LoW__eiKKkig/viewform?embedded=true">
                                <IconContainer>
                                    <Icon src={person} alt="Logo" />
                                    <IconText>Create your own loan</IconText>
                                </IconContainer>
                            </NavLink>
                        </ActiveIcons>
                    </IconsRow>
            </IconRowContainer>
            <FormText>
                <FormHeader>
                    Don’t see what you want to buy here? Help us curate a loan option for you.
                </FormHeader>
                <FormSubheader>
                    At Dili, we are constantly looking to serve our customers better. If your requirement does not fit in the categories listed above, or the merchant you wish to buy from is not currently signed up with Dili, tell us about it and we will try to curate a loan option for you. 
                </FormSubheader>
            </FormText>
            <FormContainer>
                <FormEmbed src="https://docs.google.com/forms/d/e/1FAIpQLScbyaRY2G20xafoCvqNfw_37ZlsT9-bR63qV-LoW__eiKKkig/viewform?embedded=true" width="640" height="640" frameBorder="0">
                    Loading...
                </FormEmbed>
            </FormContainer>
        </div>
    )
}

export default ShopHeader

/* COMPONENTS */

const TextContainer = styled.div`
    margin: 160px auto 0px auto;
    width: 60%;
    text-align: center;
    @media (max-width: ${props => props.theme.screen.md}) {
        width: 80%;
    }

    @media (max-width: ${props => props.theme.screen.sm}) {
        width: 80%;
    }

    @media (max-width: ${props => props.theme.screen.xs}) {
        width: 80%;
    }
`

const Headline = styled.div`
    color: #142630;
    font-size: 48px;
    font-weight: bold;
    letter-spacing: -0.02em;
    margin-left: auto;
    margin-right: auto;
    width: 60%;
    line-height: 1.2;

    @media (max-width: ${props => props.theme.screen.md}) {
        font-size: 48px;
        width: 80%;
    }

    @media (max-width: ${props => props.theme.screen.sm}) {
        font-size: 48px;
        width: 80%;
    }

    @media (max-width: ${props => props.theme.screen.xs}) {
        font-size: 36px;
    }
`

const HeaderText = styled.div`
    color: #465F6D;
    font-size: 18px;
    margin-top: 32px;
    line-height: 1.5;
    width: 60%;
    margin-left: auto;
    margin-right: auto;

    @media (max-width: ${props => props.theme.screen.md}) {
        width: 80%
    }

    @media (max-width: ${props => props.theme.screen.sm}) {
        width: 80%
    }

    @media (max-width: ${props => props.theme.screen.xs}) {
        width: 80%
    }
`

const Ribbon = styled.div`
    color: white;
    background-color: #F29D19;
    font-size: 16px;
    padding: 12px;
    border-radius: 10px;
    text-align: center;
    font-weight: bold;
    width: 80%;
    margin-left: auto;
    margin-right: auto;

    @media (max-width: ${props => props.theme.screen.xs}) {
        width: 90%;
    }
`

const IconsRow = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 60px;

    @media (max-width: ${props => props.theme.screen.md}) {
        flex-direction: column;
        justify-content: center;
    }

    @media (max-width: ${props => props.theme.screen.sm}) {
        flex-direction: column;
        justify-content: center;
    }

    @media (max-width: ${props => props.theme.screen.xs}) {
        flex-direction: column;
        justify-content: center;
    }
`

const IconRowContainer = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
`


const IconContainer = styled.div`
    margin-left: 16px;
    margin-right: 16px;
    margin-bottom: 0px;
    align-items: center;
    justify-content: center;
`

const Line = styled.div`
    margin-left: 16px;
    margin-right: 16px;
    align-items: center;
    justify-content: center;

    @media (max-width: ${props => props.theme.screen.md}) {
        display: none;
    }

    @media (max-width: ${props => props.theme.screen.sm}) {
        display: none;
    }

    @media (max-width: ${props => props.theme.screen.xs}) {
        display: none;
    }
`

const IconText = styled.div`
    width: 120px;
    margin-top: 16px;
    text-align: center;
    font-size: 16px;
    line-height: 1.5;
    color: #142630;

    @media (max-width: ${props => props.theme.screen.md}) {
        width: 100px;
        font-size: 14px;
    }

    @media (max-width: ${props => props.theme.screen.sm}) {
        width: 100px;
        font-size: 14px;
    }

    @media (max-width: ${props => props.theme.screen.xs}) {
        width: 100px;
        font-size: 14px;
    }
`

const ActiveIcons = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
`

const InactiveIcons = styled.div`
    display: flex;
    flex-wrap: wrap;

    @media (max-width: ${props => props.theme.screen.md}) {
        flex-direction: column-reverse;
    }

    @media (max-width: ${props => props.theme.screen.sm}) {
        flex-direction: column-reverse;
    }

    @media (max-width: ${props => props.theme.screen.xs}) {
        display: none;
    }
`

const InactiveContainer = styled.div`
`

const Icon = styled.img`
    width: 5vw;
    margin-left: 20px;
    margin-right: 20px;

    @media (max-width: ${props => props.theme.screen.md}) {
        width: 48px;
    }

    @media (max-width: ${props => props.theme.screen.sm}) {
        width: 48px;
    }

    @media (max-width: ${props => props.theme.screen.xs}) {
        width: 48px;
        margin-left: 26px;
        margin-right: 26px;
    }
`

export const NavLink = styled(Link)`
    text-decoration: none;
    margin-bottom: 0px;
`

const FormText = styled.div`
    flex-direction: column;
    justify-content: center;
    margin-top: 120px;
`

const FormHeader = styled.div`
    text-align: center;
    font-size: 36px;
    font-weight: bold;
    color: #142630;
    width: 60%;
    margin-left: auto;
    margin-right: auto;

    @media (max-width: ${props => props.theme.screen.md}) {

    }

    @media (max-width: ${props => props.theme.screen.sm}) {
        font-size: 40px;
        width: 80%;
    }

    @media (max-width: ${props => props.theme.screen.xs}) {
        font-size: 24px;
        width: 80%;
    }
`

const FormSubheader = styled.div`
    text-align: center;
    margin-top: 40px;
    margin-bottom: 40px;
    margin-left: auto;
    margin-right: auto;
    width: 640px;
    font-size: 20px;
    color: #465F6D;
    line-height: 1.5;

    @media (max-width: ${props => props.theme.screen.md}) {

    }

    @media (max-width: ${props => props.theme.screen.sm}) {
        width: 480px;
    }

    @media (max-width: ${props => props.theme.screen.xs}) {
        width: 360px;
        font-size: 14px;
    }
`

const FormContainer = styled.div`
    display: flex;
    justify-content: center;
    text-align: center;
    padding-top: 24px;
    padding-bottom: 80px;
`

const FormEmbed = styled(Iframe)`
    width: 640px;
    height: 640px;
`