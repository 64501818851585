/* IMPORTS */

import React from "react"
import styled from "styled-components"
import { graphql, useStaticQuery, Link } from "gatsby"
import Img from "gatsby-image"

import { HalfColumn, TwoColumn } from "../global"

/* ICONS */

import arts from "../../images/icons/Icon/Arts.png"
import education from "../../images/icons/Icon/Education.png"
import skilling from "../../images/icons/Icon/Skilling.png"
import fitness from "../../images/icons/Icon/Fitness.png"
import travel from "../../images/icons/Icon/Travel.png"
import health from "../../images/icons/Icon/Health.png"
import business from "../../images/icons/Icon/Business.png"
import person from "../../images/icons/Icon/Person.png"

import artsgrey from "../../images/icons/Icon/Gray/Arts.png"
import skillinggrey from "../../images/icons/Icon/Gray/Skilling.png"
import healthgrey from "../../images/icons/Icon/Gray/Health.png"
import travelgrey from "../../images/icons/Icon/Gray/Travel.png"
import businessgrey from "../../images/icons/Icon/Gray/Business.png"

import line from "../../images/vertlign.png"

/* FUNCTION */

const ShopWindow = () => {
    return (
        <WindowContainer>
            <ShopCategory>
                <CategoryInfo>
                    <Icon src={arts}></Icon>
                    <CategoryHeader>Education beyond the classroom</CategoryHeader>
                    <CategoryText>
                    Enhance your formal education with the help of the best online or in-class tutorials. Sign up for the best online or classroom tutorials to augment yours or your loved one’s formal education in school, college or anywhere else and pay through a dili loan. Regardless of whether this is for kindergarten or IIT/Medical preparation, a dili loan can ensure you are coached by the best and dramatically your chances of success.
                    </CategoryText>
                </CategoryInfo>
                <Stores>
                    <Store>
                        <StoreImg></StoreImg>
                        <StoreHeader>Gold's Gym</StoreHeader>
                        <StoreText>Health and Fitness</StoreText>
                        <StoreButton>Shop now</StoreButton>
                    </Store>
                    <Store>
                        <StoreImg></StoreImg>
                        <StoreHeader>Gold's Gym</StoreHeader>
                        <StoreText>Health and Fitness</StoreText>
                        <StoreButton>Shop now</StoreButton>
                    </Store>
                    <Store>
                        <StoreImg></StoreImg>
                        <StoreHeader>Gold's Gym</StoreHeader>
                        <StoreText>Health and Fitness</StoreText>
                        <StoreButton>Shop now</StoreButton>
                    </Store>
                    <Store>
                        <StoreImg></StoreImg>
                        <StoreHeader>Gold's Gym</StoreHeader>
                        <StoreText>Health and Fitness</StoreText>
                        <StoreButton>Shop now</StoreButton>
                    </Store>
                </Stores>
            </ShopCategory>
        </WindowContainer>
    )
}

export default ShopWindow

/* COMPONENTS */

const WindowContainer = styled.div`
    width: 90%;
    margin: 160px auto 80px auto;
`

const CategoryHeader = styled.div`
    color: #142630;
    font-size: 36px;
    font-weight: bold;
    letter-spacing: -0.02em;
    margin-right: 40px;
    width: 40%;
`

const CategoryText = styled.div`
    color: #465F6D;
    font-size: 20px;
    letter-spacing: -0.02em;
    line-height: 1.5;
    margin-left: 40px;
    width: 60%;
`


const CategoryInfo = styled.div`
    display: flex;
    margin-bottom: 120px;
`

const ShopCategory = styled.div`
    display: flex;
    flex-direction: column;
`

const Icon = styled.img`
    width: 80px;
    height: 80px;
    margin-right: 80px;
`

const Stores = styled.div`
    display: flex;
    flex-wrap: wrap;
`

const Store = styled.div`
    display: flex;
    flex-direction: column;
    margin-right: 24px;
    margin-bottom: 80px;
`

const StoreImg = styled.img`
    width: 400px;
    height: 240px;
    background: lightblue;
    margin-bottom: 16px;
    border-radius: 20px;
`

const StoreHeader = styled.div`
    font-size: 24px;
    margin-bottom: 8px;
    font-weight: bold;
`

const StoreText = styled.div`
    font-size: 18px;
    color: grey;
    margin-bottom: 16px;
`

const StoreButton = styled.button`
    background: black;
    color: white;
    font-weight: bold;
    font-size: 14px;
    width: 96px;
    padding-top: 8px;
    padding-bottom: 8px;
    border-radius: 40px;
`