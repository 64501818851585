import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import Iframe from "react-iframe"
import FadeIn from 'react-fade-in';

import Layout from "../components/common/layout/layout"
import SEO from "../components/common/layout/seo"
import Navigation from "../components/common/navigation/navigation"
import Footer from "../components/sections/footer"

import ShopHeader from "../components/sections/shopheader"
import ShopWindow from "../components/sections/shopwindow"

import { SectionDivider, HalfColumn, TwoColumn } from "../components/global"

const WhereToShopPage = () => (
  <Layout>
    <SEO title="Where to Shop | Dili - Zero Cost EMI Option for Professional Skilling" />
    <Navigation></Navigation>
    <FadeIn>
    <ShopHeader></ShopHeader>
    <Spacing></Spacing>
    </FadeIn>
    <Footer></Footer>
  </Layout>
)

export default WhereToShopPage


const FormText = styled.div`
    flex-direction: column;
    justify-content: center;
`

const FormHeader = styled.div`
    text-align: center;
    font-size: 48px;
    font-weight: bold;
    color: #142630;
    width: 60%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 80px;

    @media (max-width: ${props => props.theme.screen.md}) {

    }

    @media (max-width: ${props => props.theme.screen.sm}) {
        font-size: 40px;
        width: 80%;
    }

    @media (max-width: ${props => props.theme.screen.xs}) {
        font-size: 24px;
        width: 80%;
    }
`

const FormSubheader = styled.div`
    text-align: center;
    margin-top: 40px;
    margin-bottom: 40px;
    margin-left: auto;
    margin-right: auto;
    width: 640px;
    font-size: 18px;
    color: #465F6D;
    line-height: 1.5;

    @media (max-width: ${props => props.theme.screen.md}) {

    }

    @media (max-width: ${props => props.theme.screen.sm}) {
        width: 480px;
    }

    @media (max-width: ${props => props.theme.screen.xs}) {
        width: 360px;
        font-size: 14px;
    }
`

const FormContainer = styled.div`
    display: flex;
    justify-content: center;
    text-align: center;
    padding-top: 24px;
    padding-bottom: 80px;
`

const FormEmbed = styled(Iframe)`
    width: 640px;
    height: 640px;
`

export const Spacing = styled.div`
    margin-bottom: 120px;
`
